body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker-wrapper {
    display: block;
}

.react-datepicker__input-container {
    display: block;
    /* position: relative; */
    /* display: inline-block; */
}

.previouslyselected {
    border-color: darkgrey; 
    border-width: 3px; 
    border-style: dashed;
}

.selected {
    border-color: black; 
    border-width: 3px; 
    border-style: solid;
}

.striped button:nth-child(even) {background: #eee}
.striped button:nth-child(odd) {background: #fff}

.tooltip {
    position: relative;
    display: contents;
    font-weight: bold;
    font-size: larger;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
div.page_break + div.page_break{
    page-break-before: always;
}

.page_break { page-break-before: always; }
.clickable {
    cursor: pointer;
    text-decoration: underline;
}
/* per il break page della stampa */
div.page_break + div.page_break{
    page-break-before: always;
}

.page_break { page-break-before: always; }


/* !important;   per dargli precedenza*/
path.ygrid.crisp:nth-child(odd) {
    stroke: transparent !important;
}

 /* usato per le tabelle */
#statsDiv .tableWrapper table {
    width: 100%;
}

#totDiv .progress {
    width: 94%;
    margin-left: 3%;
}
 
#statsDiv .tableWrapper  td {
    text-align: center;
    height: 30px;
    vertical-align: middle;
    width: 20%;
    border: 1px solid;
}

 /* usato per la classe  */
 #statsDiv .tableWrapper, #totDiv .tableWrapper{
    padding: 0 56px;
}


#statsDiv .labels,  #totDiv .labels {
    float: left;
}

#statsDiv .onelabel {
    line-height: 30px;
    display: table;
    clear: both;
    vertical-align: middle;
}
/* # usato per un id */
.error {
    border-color: red;
    border-width: 4px;
}

.cbdiverror {
    color: red;
}
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
    display:none;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #F0F0F0;
    padding: 2.5em 0em 0;
    /*font-size: 1.15em;*/
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em 1%;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
