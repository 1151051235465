.tooltip {
    position: relative;
    display: contents;
    font-weight: bold;
    font-size: larger;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }